import {useLeafletContext} from "@react-leaflet/core";
import L from 'leaflet';
import {memo, useCallback, useEffect, useRef, useState} from "react";
import GeoTiffLayer from "../GeoTiffLayer";

const FloorControl = ({floors = [], setLoaded, handleSelectFloor, currentFloor = null}) => {
    const context = useLeafletContext();
    const buttonRefs = useRef([]);
    const allFloorsLoadedRef = useRef(false);
    const [rasterLoaded, setRasterLoaded] = useState({});
    const [selectedFloor, setSelectedFloor] = useState("");
    const controlAdded = useRef(false);

    const floorSelectionHandler = useCallback((floor) => {
        setSelectedFloor(floor.name);
        handleSelectFloor(floor.value);
    }, [handleSelectFloor]);

    // change floors based on most recent EML location
    useEffect(() => {
        if (currentFloor && allFloorsLoadedRef.current && currentFloor.name !== selectedFloor) {
            floorSelectionHandler(currentFloor);
            // console.log("set floor to ", currentFloor);
        }
    }, [currentFloor, floorSelectionHandler, selectedFloor]);

    useEffect(() => {
        const floorsLoaded = floors.map(f => rasterLoaded[f.name]).filter(f => f);
        const allFloorsLoaded = floorsLoaded.length === floors.length;
        setLoaded(allFloorsLoaded);
        // select first floor by default
        if (floors.length && allFloorsLoaded && !allFloorsLoadedRef.current) {
            allFloorsLoadedRef.current = true;
            setSelectedFloor(floors[0].name);
        }
    }, [rasterLoaded, floors, setLoaded, setSelectedFloor]);

    useEffect(() => {
        const FloorSelectorControl = L.Control.extend({
            onAdd: function (map) {
                const floorSelector = L.DomUtil.create('div', "floor-plan-selector-container");
                floors.forEach((floor, idx) => {
                    const floorButtonElement = L.DomUtil.create("a", "floor-plan-button", floorSelector);
                    floorButtonElement.innerText = floor.name;
                    L.DomEvent.disableClickPropagation(floorButtonElement);
                    L.DomEvent.on(floorButtonElement, "click", () => floorSelectionHandler(floor));
                    buttonRefs.current.push({floor, floorButtonElement});
                })
                floorSelector.title = "Select Floor";
                return floorSelector;
            },

            onRemove: function (map) {
                // Nothing to do here
            }
        });

        const floorSelectorControl = new FloorSelectorControl({position: "topleft"});
        if (!controlAdded.current) {
            context.map.addControl(floorSelectorControl);
            controlAdded.current = true;
        }

        return () => {
            context.map.removeControl(floorSelectorControl);
            controlAdded.current = false;
            buttonRefs.current = [];
        };
    }, [floors, context.map, floorSelectionHandler]);

    // change button selected state
    useEffect(() => {
        if (selectedFloor && buttonRefs.current) {
            // remove selected class from all buttons
            buttonRefs.current.forEach(f => L.DomUtil.removeClass(f.floorButtonElement, "floor-plan-button-selected"));
            // add selected class
            const selectedElement = buttonRefs.current.find(f => f.floor.name === selectedFloor);
            if (selectedElement) {
                L.DomUtil.addClass(selectedElement.floorButtonElement, "floor-plan-button-selected");
            }
        }
    }, [selectedFloor]);

    let loaded = (floor) => (loaded) => setRasterLoaded(prev => ({...prev, [floor]: loaded}));
    return <>
        {
            floors.map(floor => <GeoTiffLayer key={floor.name}
                                              url={floor.url}
                                              options={{resolution: 256, opacity: 1, zoom: 30}}
                                              setLoaded={loaded(floor.name)}
                                              isVisible={selectedFloor === floor.name}
            />)
        }</>;
}

export default memo(FloorControl);
