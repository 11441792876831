import {useEffect, useRef} from "react";
import {useLeafletContext} from "@react-leaflet/core";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import {convertToLatLng, loadFloorPlan} from "../../util/EliFloorPlans";
import L from "leaflet";

const GeoTiffLayer = ({url, options, setLoaded, isVisible = false}) => {
    const geoTiffLayerRef = useRef({layer: null, latLng: null});
    const loading = useRef(false);
    const context = useLeafletContext();

    // load layer
    useEffect(() => {
        if (loading.current) return;

        loading.current = true;
        loadFloorPlan(url)
            .then(georaster => {
                options.georaster = georaster;
                const {xmax, xmin, ymax, ymin} = georaster;
                const layer = new GeoRasterLayer(options);
                const latLng = convertToLatLng({xmin, xmax, ymin, ymax});
                geoTiffLayerRef.current = {layer, latLng};
                setLoaded(true);
            });
        return () => {
        };
    }, [context, url, options, setLoaded]);

    // hide/show layer
    useEffect(() => {
        const container = context.layerContainer || context.map;
        if (geoTiffLayerRef.current && geoTiffLayerRef.current.layer) {
            if (isVisible) {
                container.addLayer(geoTiffLayerRef.current.layer)
                const {lat, lng} = geoTiffLayerRef.current.latLng;
                if (lat && lng) {
                    // console.log("flying to", id);
                    const currentZoom = container.getZoom();
                    const maxZoom = container.getMaxZoom();
                    // fix for floor plan off by a bit when hiding/showing
                    context.map.flyTo(new L.LatLng(lat, lng),
                        maxZoom - currentZoom < 5 ? currentZoom : maxZoom,
                        {animate: false});
                }
            } else {
                container.removeLayer(geoTiffLayerRef.current.layer)
            }
        }
    }, [isVisible, context]);
    return null;
};

export default GeoTiffLayer;
